<template>
  <div class="container">
    <div class="content">
      <div class="content-flex">
        <aside class="aside-content">
          <div class="aside-wrapper">
            <div class="ht-card green">
              <div class="ht-card-wrapper">
                <div class="ht-card-header">
                  <h1 class="">ตรวจล็อตเตอรี่</h1>
                </div>
                <form action="">
                  <ul>
                    <li>
                      <select class="form-select" value="16 มิถุนายน 2564">
                        <option value="16 มิถุนายน 2564">16 มิถุนายน 2564</option>
                      </select>
                    </li>
                    <li>
                      <input class="form-control" maxlength="6" type="text" placeholder="กรอกตัวเลข เพื่อตรวจสลาก" />
                    </li>
                    <li>
                      <input class="form-control" maxlength="6" type="text" placeholder="กรอกตัวเลข เพื่อตรวจสลาก" />
                    </li>
                    <li>
                      <input class="form-control" maxlength="6" type="text" placeholder="กรอกตัวเลข เพื่อตรวจสลาก" />
                    </li>
                  </ul>
                  <button type="submit" class="ht-button gray">ตรวจล็อตเตอรี่</button>
                </form>
              </div>
            </div>
            <!-- end check lottery form -->
            <div class="ht-card gray">
              <div class="ht-card-wrapper">
                <div class="">
                  <h1 class="">ข่าวหวยประจำวัน</h1>
                </div>
                <ul>
                  <li>
                    <div class="news-img">
                      <img
                        src="https://images.unsplash.com/photo-1484627147104-f5197bcd6651?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                        alt=""
                      />
                    </div>
                    <div class="news-detail">
                      <a href="#" class=""><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, quod.</p> </a>
                    </div>
                  </li>
                  <li>
                    <div class="news-img">
                      <img
                        src="https://images.unsplash.com/photo-1484627147104-f5197bcd6651?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                        alt=""
                      />
                    </div>
                    <div class="news-detail">
                      <a href="#" class=""><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, quod.</p> </a>
                    </div>
                  </li>
                  <li>
                    <div class="news-img">
                      <img
                        src="https://images.unsplash.com/photo-1484627147104-f5197bcd6651?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                        alt=""
                      />
                    </div>
                    <div class="news-detail">
                      <a href="#" class=""><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, quod.</p> </a>
                    </div>
                  </li>
                  <li>
                    <div class="news-img">
                      <img
                        src="https://images.unsplash.com/photo-1484627147104-f5197bcd6651?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                        alt=""
                      />
                    </div>
                    <div class="news-detail">
                      <a href="#" class=""><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, quod.</p> </a>
                    </div>
                  </li>
                </ul>

                <!-- end content check lottery -->

                <div>
                  <button class="ht-button green">เพิ่มเติม</button>
                </div>
              </div>
            </div>
            <!-- end daily lottery -->
          </div>
        </aside>

        <main class="main-content">
          <div class="main-content-wrapper">
            <div class="ht-card gray">
              <div class="ht-card-wrapper">
                <Form @submit="handleAppeal" :validation-schema="schema">
                  <div class="flex flex-col p-5">
                    <div>
                      <h1>แจ้งเรื่องร้องเรียน</h1>
                    </div>
                    <div class="form-group">
                      <Field name="topic" type="text" class="form-control" placeholder="ชื่อเรื่องร้องเรียน" />
                      <ErrorMessage name="topic" class="error-feedback" />
                    </div>
                    <br />
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <Field name="name" type="text" class="form-control" placeholder="ชื่อผู้ร้องเรียน" />
                          <ErrorMessage name="name" class="error-feedback" />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <Field name="sub_topic" as="select" class="form-select">
                            <option value="" selected>หัวข้อที่ร้องเรียน</option>
                            <option value="สอบถามเรื่องการซื้อขายสลาก">สอบถามเรื่องการซื้อขายสลาก</option>
                            <option value="ร้องเรียนเกี่ยวกับตัวแทน">ร้องเรียนเกี่ยวกับตัวแทน</option>
                            <option value="ติชม - เสนอแนะ">ติชม - เสนอแนะ</option>
                          </Field>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <Field name="number" type="text" class="form-control" placeholder="เบอร์โทรผู้ร้องเรียน" />
                          <ErrorMessage name="number" class="error-feedback" />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <Field name="email" type="text" class="form-control" placeholder="อีเมลผู้ร้องเรียน" />
                          <ErrorMessage name="email" class="error-feedback" />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="form-group">
                      <Field name="detail" type="text" class="form-control" placeholder="รายละเอียด" />
                      <ErrorMessage name="detail" class="error-feedback" />
                      <!-- <textarea type="text" class="form-control" name="detail" placeholder="รายละเอียด" style="height: 130px" /> -->
                    </div>
                    <br />
                    <div class="form-group">
                      <center>
                        <!-- <Field name="img" type="file" class="btn btn-secondary" @change="onFileChange" rules="image/*" /> -->
                        <div v-if="!image">
                          <Field name="img" type="file" @change="onFileChange" class="btn btn-secondary" rules="image/*" />
                         
                        </div>
                        <div v-else> 
                          <Field name="base64" type="hidden" class="btn btn-secondary" v-model="base64" />
                          <img :src="image" />
                          <button @click="removeImage">Remove image</button>
                        </div>
                      </center>
                    </div>
                    <div>
                      <button class="btn confirm" :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>ส่งข้อความ</span>
                      </button>
                    </div>
                  </div>
                </Form>
                <!-- end government lottery results -->
              </div>
            </div>
            <div class="flex flex-col mt-7 p-5 ht-card gray">
              <div class="row">
                <div class="col">
                  <p>
                    <span> <font-awesome-icon :icon="['fas', 'phone-alt']" class="icon" /> เบอร์โทร</span> 021234567<br />
                    หรือโทร 0812345678
                  </p>
                </div>
                <div class="col">
                  <p>
                    <span> FACEBOOK</span><br />
                    หงส์ล็อตเตอรี่ ออนไลน์
                  </p>
                </div>
                <div class="col">
                  <p>
                    <span>LINE ID</span><br />
                    Hong-lottery
                  </p>
                </div>
              </div>
            </div>
            <!-- end government lottery results -->

            <div class="helper">
              <div class="helper-wrapper">
                <div class="helper-items">
                  <a href="#" class="item">
                    <div class="item-wrapper">
                      <div class="item-content">
                        <font-awesome-icon :icon="['fas', 'users']" class="icon" />
                        <p class="text">ตัวแทนจำหน่าย</p>
                      </div>
                    </div>
                  </a>
                  <a href="#" class="item">
                    <div class="item-wrapper">
                      <div class="item-content">
                        <font-awesome-icon :icon="['fas', 'building']" class="icon" />
                        <p class="text">เกี่ยวกับเรา</p>
                      </div>
                    </div>
                  </a>
                  <router-link to="/appeal" class="item">
                    <div class="item-wrapper">
                      <div class="item-content">
                        <font-awesome-icon :icon="['fas', 'phone-alt']" class="icon" />
                        <p class="text">ร้องเรียน</p>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import AppealService from '../services/appeal.service'

export default {
  name: 'Appeal',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    const schema = yup.object().shape({
      topic: yup.string().required('topic is required!'),
      name: yup.string().required('name is required!'),
      number: yup.string().required('phone number is required!'),
      email: yup.string().required('email is required!'),
      detail: yup.string().required('detail is required!')
    })

    return {
      loading: false,
      message: '',
      schema,
      image: '',
      base64: 'waiting for value'
    }
  },
  methods: {
    async handleAppeal(eleForm) {
      this.loading = true
      const response = await AppealService.Appeal(eleForm)
      this.loading = false
      this.$toast.success('Appeal has been sent')
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      var image = new Image()
      var reader = new FileReader()
      var vm = this

      reader.onload = (e) => {
        vm.image = e.target.result
        var base64String = e.target.result
        this.base64 = base64String
      }
      reader.readAsDataURL(file)
    },
    removeImage: function (e) {
      this.image = ''
    }
  }
}
</script>

<style scoped>
p span,
h1 {
  color: #63c289;
}
p {
  font-size: 28px;
}
.btn {
  border-radius: 25px;
}
.confirm {
  background-color: #63c289;
  color: #fff;
  width: 100%;
  padding: 8px;
  margin: 20px auto;
  font-size: 25px;
}
</style>