import axios from 'axios'

const API_URL = process.env.VUE_APP_ROOT_API

class AppealService {

    async Appeal(element) {
        return axios.post(API_URL + '/appeal', {
            topic: element.topic,
            name: element.name,
            sub_topic: element.sub_topic,
            number: element.number,
            email: element.email,
            detail: element.detail,
            img: element.base64
          })
    }

  }
  
  export default new AppealService()
  